import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "../Category/scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Link } from "react-router-dom";
import classNames from "classnames";
import noPhotoImg from "images/no-photo.svg";
import * as S from "@components/molecules/ProductDescription/styles";
import { RichTextContent } from "@components/atoms";
import { isEmpty } from "lodash";
import { ProductListHeader } from "@components/molecules";
import { sanitize } from "dompurify";
import draftToHtml from "draftjs-to-html";
import { FilterSidebar, ProductList } from "../../@next/components/organisms";
import { Breadcrumbs, ProductsFeatured } from "../../components";
import { generateCollectionUrl, getDBIdFromGraphqlId, maybe } from "../../core/utils";

var Page = function Page(_ref) {
  var _collection$translati, _collection$translati2;

  var collection = _ref.collection,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      clearFilters = _ref.clearFilters,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  var breadcrumbs = [{
    link: ["/collection", "/".concat(collection.slug), "/".concat(getDBIdFromGraphqlId(collection.id, "Collection"), "/")].join(""),
    value: (collection === null || collection === void 0 ? void 0 : collection.translation) && (collection === null || collection === void 0 ? void 0 : (_collection$translati = collection.translation) === null || _collection$translati === void 0 ? void 0 : _collection$translati.name) ? collection.translation.name : collection.name
  }];
  return React.createElement("div", {
    className: "collection"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), collection.backgroundImage && collection.backgroundImage.url && React.createElement(Link, {
    to: generateCollectionUrl(collection.id, (collection === null || collection === void 0 ? void 0 : collection.translation) && (collection === null || collection === void 0 ? void 0 : (_collection$translati2 = collection.translation) === null || _collection$translati2 === void 0 ? void 0 : _collection$translati2.name) ? collection.translation.name : collection.name),
    key: collection.id
  }, React.createElement("div", {
    className: classNames("home-page__categories__list__image", {
      "home-page__categories__list__image--no-photo": !collection.backgroundImage
    }),
    style: {
      backgroundImage: "url(".concat(collection.backgroundImage ? collection.backgroundImage.url : noPhotoImg, ")")
    }
  })), !isEmpty(JSON.parse(collection.descriptionJson)) && sanitize(draftToHtml(JSON.parse(collection.descriptionJson))).length >= 50 && React.createElement(S.Description, null, React.createElement(RichTextContent, {
    descriptionJson: collection.translation && collection.translation.descriptionJson || collection.descriptionJson
  })), React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), React.createElement(ProductListHeader, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), canDisplayProducts && React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  })), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;